export type AuthorizationResponse = {
  accessToken: string
  id: string
  name: string
}

export type OrderCategory = {
  id: number
  name: string
  slug: string
}

export type OrderPlan = {
  price: {
    currency: string
    value: number
  }
  recurring: {
    interval: string
    period: number | null
  }
  sku: string
  type: 'recurring' | 'one-off'
}

export type OrderTotalDetails = {
  discount: number
  shipping: number
  taxAmount: number
  taxPercentage: string
}

export type OrderItem = {
  product: {
    category: OrderCategory
    description: string
    id: number
    name: string
    plan: OrderPlan
    requiresPrescription: boolean
    slug: string
  }
  quantity: number
}

export enum VisitStatus {
  Approved = 'approved',
  Registered = 'registered',
  Rejected = 'rejected',
}

export enum Brand {
  'andsons-sg' = 'andsons-sg',
  'ova-sg' = 'ova-sg',
  'modules-sg' = 'modules-sg',
}

export type Order = {
  category?: string
  orderId: string
  orderRef: string
  orderItems: OrderItem[]
  isReconsultation?: number
  reconsultationItems?: OrderItem[]
  txnRefId: string
}

export type ConsultationDetails = {
  visit: {
    id: string
    doctor: string
    location: string
    status: VisitStatus
    reason?: string
  }
  patient: {
    id: string
    name: string
    nric: string
    userId: string
  }
  order: Order
  status: string
  brand: Brand
  startedAt?: string
  endedAt?: string
  markedNoShowAt?: string
  isNoShow?: boolean
}

export type MedicalPrescriptionProduct = {
  name: string
  slug: string
  instructions: {
    frequency: string
    remarks: string
  }
  status?: 'approved' | 'draft' | 'void'
}
