import { useMutation, useQueryClient } from '@tanstack/react-query'

import { endNoShowConsultation } from '../../services'

export const useNoShowConsultationMutation = () => {
  const queryClient = useQueryClient()

  const { error, mutate, ...mutation } = useMutation(endNoShowConsultation, {
    onSuccess: () => {
      // eslint-disable-next-line
      queryClient.invalidateQueries({ queryKey: ['consultation'] })
    },
  })

  return {
    error,
    mutate,
    ...mutation,
  }
}
