import { useQueryClient } from '@tanstack/react-query'
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useToaster } from '../../hooks'
import { useCreateTreatmentMutation } from '../../queries'
import { SelectedTreatmentProduct } from '../../services'
import { Product, ProductPlan } from '../../services/products/types'
import { usePlatoContext } from '../Plato/PlatoProvider'
import { usePrescriptionsContext } from '../Prescriptions/PrescriptionsProvider'
import { useProductsContext } from './ProductsProvider'

export type TreatmentProduct = {
  plan: {
    imageURL?: string
    name: string
  } & ProductPlan
  quantity: number
  slug: string
  category: {
    slug: string
  }
  instructions: {
    description?: string | null
    dosage?: string
    medicationFrequency?: string
  }
  name: string
  needsPrescription: boolean
  type?: 'general-consultation'
  refId?: string
}

export type CartContext = {
  addToTreatment: (item: TreatmentProduct) => void
  category: string
  createTreatmentPlan: (orderId: string) => {}
  decreaseQuantity: (item: TreatmentProduct) => void
  getDescription: (plan: ProductPlan) => string | undefined
  increaseQuantity: (item: TreatmentProduct) => void
  isLoading: boolean
  hasTreatmentPlan: boolean
  products: Product[]
  removeFromTreatment: (item: TreatmentProduct) => void
  setCategory: Dispatch<SetStateAction<string>>
  treatment: TreatmentProduct[]
  treatmentPrice: number
}

export type TreatmentProviderProps = PropsWithChildren<{}>

const treatmentContext = createContext<CartContext | undefined>(undefined)

export const TreatmentProvider: FC<TreatmentProviderProps> = ({ children }) => {
  const { order, treatment: existingTreatment } = usePlatoContext()
  const { addTreatmentProductToPrescriptions, removeFromPrescriptions } =
    usePrescriptionsContext()
  const { products, allProducts } = useProductsContext()
  const [category, setCategory] = useState<string>(
    order.category || order.orderItems?.[0].product.category.slug,
  )

  const queryClient = useQueryClient()

  const [firstRun, setFirstRun] = useState<boolean>(true)

  const [treatment, setTreatment] = useState<TreatmentProduct[]>([])
  const [treatmentPrice, setTreatmentPrice] = useState<number>(0)

  const [hasTreatmentPlan, setHasTreatmentPlan] = useState<boolean>(false)

  const getSelectedPlan = useCallback((product: Product) => {
    return product.plans.filter(plan => plan.selected)?.[0] as ProductPlan
  }, [])

  const mapExistingTreatment = useCallback(() => {
    if (
      !existingTreatment ||
      treatment.length > 0 ||
      !firstRun ||
      allProducts.length === 0
    ) {
      return
    }

    setFirstRun(false)
    const showTreatmentInCart: TreatmentProduct[] = []

    existingTreatment.products.forEach(treatmentPlan => {
      showTreatmentInCart.push({
        quantity: treatmentPlan.quantity,
        slug: treatmentPlan.slug,
        plan: {
          ...getSelectedPlan(treatmentPlan),
          name: treatmentPlan.name,
        },
        instructions: treatmentPlan.instructions,
        name: treatmentPlan.name,
        needsPrescription: treatmentPlan.needsPrescription,
        category: treatmentPlan.category,
      })
      setCategory(treatmentPlan.category.slug)
    })

    setTreatment(showTreatmentInCart)
    setHasTreatmentPlan(true)
  }, [
    allProducts.length,
    existingTreatment,
    firstRun,
    getSelectedPlan,
    treatment.length,
  ])

  useEffect(() => {
    if (!existingTreatment) {
      return
    }

    mapExistingTreatment()
  }, [existingTreatment, mapExistingTreatment])

  const { mutate, isLoading } = useCreateTreatmentMutation()

  const toast = useToaster()

  const addToTreatment = (item: TreatmentProduct) => {
    setTreatment([...treatment, item])
    setCategory(item.category.slug)
    if (item.needsPrescription) {
      addTreatmentProductToPrescriptions(item)
    }
  }

  const removeFromTreatment = (item: TreatmentProduct) => {
    setTreatment(treatment.filter(product => product != item))
    if (item.needsPrescription) {
      removeFromPrescriptions(item.slug)
    }
  }

  const increaseQuantity = (item: TreatmentProduct) => {
    const copy = [...treatment]

    const itemIndex = copy.findIndex(
      product => product.plan.price.id === item.plan.price.id,
    )

    const selectedProduct = copy[itemIndex]

    selectedProduct.quantity++

    copy.splice(itemIndex, 1, selectedProduct)
    setTreatment(copy)
  }

  const decreaseQuantity = (item: TreatmentProduct) => {
    const copy = [...treatment]

    const itemIndex = copy.findIndex(
      product => product.plan.price.id === item.plan.price.id,
    )

    const selectedProduct = copy[itemIndex]

    if (selectedProduct.quantity === 1) {
      setTreatment(treatment.filter(product => product != item))
      if (item.needsPrescription) {
        removeFromPrescriptions(item.slug)
      }
    } else if (selectedProduct.quantity > 1) {
      selectedProduct.quantity--

      copy.splice(itemIndex, 1, selectedProduct)
      setTreatment(copy)
    }
  }

  const getDescription = (plan: ProductPlan) => {
    if (!plan) return

    const sku = plan?.sku?.split('-').join(' ')
    if (!plan.recurring?.period) {
      return `${sku}\n One time purchase (${
        plan.recurring?.skuQuantity ?? 1
      } unit${(plan.recurring?.skuQuantity ?? 1) === 1 ? '' : 's'})`
    }
    if (plan.recurring?.period > 0) {
      return `${sku}\n ${plan.recurring?.period} ${
        plan.recurring?.interval
      } subscription (${plan.recurring?.skuQuantity ?? 1} unit${
        (plan.recurring?.skuQuantity ?? 1) === 1 ? '' : 's'
      })`
    }
  }

  const createProductStringRecord = (product: TreatmentProduct) => {
    return `<h4><b>${product.plan.name}</b></h4>
    <p>Quantity Prescribed: ${product.quantity}<br>
    Subscription Plan: ${getDescription(product.plan)}</p>
    <p><b>Instructions:</b></p>
    <p>${product.instructions?.description || ''}<br>
    Dosage: ${product.instructions?.dosage || 'NA'}<br>
    Medication Frequency: ${
      product.instructions?.medicationFrequency || 'NA'
    }</p>    `
  }

  const createTreatmentItem = (
    product: TreatmentProduct,
  ): SelectedTreatmentProduct => {
    return {
      plan: {
        id: product.plan.sku,
        refId: product.plan.price.id.toString(),
      },
      quantity: product.quantity,
      slug: product.slug,
      stringRecord: createProductStringRecord(product),
    }
  }

  const createTreatmentPlan = (orderId: string): {} => {
    toast.dismissAll()

    try {
      mutate(
        {
          category,
          orderId,
          products: treatment.map(createTreatmentItem),
        },
        {
          onSuccess: () => {
            setHasTreatmentPlan(true)
            toast.success('Treatment plan has successfully created.')
            queryClient.invalidateQueries({ queryKey: ['consultation'] })

            return true
          },
        },
      )
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    if (treatment.length === 0) {
      setTreatmentPrice(0)
      return
    }
    setTreatmentPrice(
      treatment.reduce(
        (sum, item) => sum + item.quantity * (item.plan?.price?.value ?? 0),
        0,
      ),
    )
  }, [treatment])

  return (
    <treatmentContext.Provider
      value={{
        addToTreatment,
        category,
        createTreatmentPlan,
        decreaseQuantity,
        getDescription,
        hasTreatmentPlan,
        increaseQuantity,
        isLoading,
        products,
        removeFromTreatment,
        setCategory,
        treatment,
        treatmentPrice,
      }}
    >
      {children}
    </treatmentContext.Provider>
  )
}

export const useTreatmentContext = (): CartContext => {
  const context = useContext(treatmentContext)

  if (!context) {
    throw new Error('useCartContext must be used within CartProvider')
  }

  return context
}
